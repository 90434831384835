<template>
    <main class="flex-1 overflow-y-auto h-full">
        <PageHead title="Allergene" sticky>
            <template #actions>
                <c-button :path="{ name: 'AllergenAdd' }">Neu</c-button>
            </template>
        </PageHead>

        <List :items="allergens"
              editRouteName="AllergenEdit"/>

        <p class="p-6 text-center text-base text-gray-400">
            {{ allergens.length }} Allergene wurden geladen.
        </p>
    </main>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Allergens',
    computed: {
        ...mapGetters('products', ['getAllergens']),
        allergens() {
            return this.getAllergens();
        },
    },
};
</script>
